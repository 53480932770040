const routeName = 'module->Accounting->Asset';
const baseUrl = '/xapi/Accounting/Asset';
const moduleAjaxService = 'backend-service'

const routes = {
    path: 'Asset',
    name: routeName,
    component: () => import('./View.vue'),
    children: [
      {
        path: 'create',
        name: routeName + '->create',
        component: () => import('./create/View')
      },
      {
        path: 'list',
        name: routeName + '->list',
        component: () => import('./list/View'),
        children: [
          {
            path: ':listPage',
            name: routeName + '->list->page',
            component: () => import('./list/page/View')
          }
        ]
      },
      {
        path: ':entityTitle',
        name: routeName + '->view',
        component: () => import('./view/View.vue')
      }/*,
      {
        path: 'external-component',
        name: routeName + '->external-component',
        component: () => $hks.loadExternalComponent('http://127.6.3.1:8000/xapi/UI/ModuleManager/getComponent/Accounting/Asset/compName'),
      }*/
    ]
}

export const module = {
  info: {
    routeName,
    name: 'Asset',
    caption: 'Accounting Asset',
    namespace: 'Accounting',
    fullName: 'Accounting-Asset'
    // viewGroup: 'general-modules'
  },
  vuexModules: ['api'],  
  sideMenu: {
    notAllowedProfiles: [],
    topSeparator: false,
    caption: 'Asset',
    icon: 'el-icon-folder',
    routeName: 'list->page',
    routeParams: { listPage: 1}
    /*children: [
      {
        caption: 'Create Entity',
        icon: 'el-icon-plus',
        routeName: 'create'
      },
      {
        caption: 'List Entities',
        icon: 'el-icon-more-outline',
        routeName: 'list->page',
        routeParams: { listPage: 1}
      }
    ]*/
  },
  router: {
    mainRouteName: 'portal-cpanel',
    routes: routes
  },
  onLoad: async function () {
    $xapi = {
      ...$xapi,
      ...$mapActions('Accounting-Asset-api', {
        getAccountingAssetDropdown: 'getDropDownList',
      }),
    }
    // promises.push($xapi.getAccountingAssetDropdown());

    /*$services['Accounting-Asset'] = {
      custom: function (a) { return a + 1;}
    }*/
    return 0}
}

export default module
